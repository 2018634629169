

.bar{
   
   padding: 5px;
}

.card {
	height: 2.5%;
}
  .title{
    font-size: 14,
  }
  .action{
	display: "flex";
    justify-Content: "space-between"
  }
 