@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

.password {
  border: 2px solid #2391da;
  height: 26px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #3d3d3c;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 20px;
  margin-bottom: 20px;
  margin: 10px, 10px, 10px, 0;
  margin-right: 20px;
}
.btn {
  border: none;
  background: #2391da;
  color: #ffffff !important;
  font-weight: 100;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 6px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.root {
  padding: 40px;
}
