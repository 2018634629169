/* imports */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

/* Root */

#root {
  height: 100%;
}

#root > div {
  height: 100%;
}

/* layout */

.layout {
  height: 100%;
  background-color: transparent !important;
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.hide-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* app */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* react admin */

#react-admin-title {
  color: #fff !important;
  margin-top: 0px !important;
}

/* typography */

h1 {
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

.MuiTypography-root.MuiTypography-caption.MuiTypography-colorTextSecondary {
  font-size: 13px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin-top: 5px;
}

.MuiTypography-root.MuiTypography-h5,
.MuiTypography-root.MuiTypography-subtitle2 {
  font-family: "Montserrat", sans-serif !important;
}

/* date rage picker calendar */
.react-daterange-picker__calendar {
  z-index: 1000 !important;
}

.mui-icon-button-override {
  color: green !important;
  height: 24px;
}

body {
  background-image: url("./layout/img/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: "Montserrat", sans-serif !important;
  overflow-x: hidden;
}

.city-background {
  background-image: url("./layout/img/city.png");
  background-size: contain;
  background-position: right 40% bottom 0%;
  background-size: 90%;
}

.mountains-background {
  background-image: url("./layout/img/mountains.png");
  background-size: contain;
  background-position: right 40% bottom 0%;
  background-size: 90%;
}

html,/* #main-content {
  padding: 30px;
} */
body {
  margin: 0;
  height: 100%;
}

code {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px !important;
  color: #1bbd62 !important;
}

.MuiTypography-body1 {
  font-family: "Montserrat", sans-serif !important;
  color: var(--secondary-color) !important;
}

/* sidebar */

.MuiListItem-dense {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

/* buttons */

button {
  padding: 0px 10px !important;
}

.btn_ {
  font-weight: 500 !important;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  font-size: 15px !important;
  /* font-size was important */
  min-width: 100px !important;
  transition: all 0.5s ease !important;
  margin-left: 4px !important;
  /* margin-bottom: 5px !important; */
}

.btn_primary {
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}
.btn_primary:hover,
.btn_primary.active {
  /* background-color: #00acee !important; */
  background-color: var(--secondary-color) !important;
  color: #fff !important;
}

.btn_secondry {
  background-color: #e4e4e4 !important;
  color: #333333 !important;
}
.btn_secondry:hover,
.btn_secondry.active {
  background-color: #333333 !important;
  color: #fff !important;
}

.btn_edit {
  background-color: #a9f5ca !important;
  color: #219a56 !important;
}

.btn_edit:hover,
.btn_edit.active {
  background-color: #219a56 !important;
  color: #fff !important;
}

.btn_delete {
  background-color: #ffdad7 !important;
  color: #f44336 !important;
}
.btn_delete:hover,
.btn_delete.active {
  background-color: #f44336 !important;
  color: #fff !important;
}

.rbc-btn-group {
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}
/* 
button.btn-danger .MuiButton-label {
  font-size: 14px;
}*/
button[type="submit"] {
  background-color: #a9f5ca !important;
  /* margin-bottom: 0px !important; */
  margin-right: 0px !important;

  color: #219a56 !important;
  font-family: "Montserrat", sans-serif !important;
}
.btn-group .btn-check:checked + .btn-outline-primary {
  color: #fff;
  /* background-color: #00acee;
  border-color: #00acee; */
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  font-weight: 500;
}
.btn-group .btn-outline-primary:hover {
  background-color: #c5edfd;
  /* color: #00acee; */
  color: var(--secondary-color);
}
.btn-group .btn-outline-primary {
  /* color: #00acee;
  border-color: #00acee; */
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn_primary {
  background-color: #c5edfd;
  /* color: #00acee; */
  color: var(--secondary-color);
}
.btn_primary:hover,
.btn_primary.active {
  /* background-color: #00acee; */
  background-color: var(--secondary-color);
  color: #fff;
}
.btn_primary:disabled {
  background-color: #e4e4e4;
  color: #333333;
}

.btn_secondry {
  background-color: #e4e4e4;
  color: #333333;
}
.btn_secondry:hover,
.btn_secondry.active {
  background-color: #333333;
  color: #fff;
}

.btn_edit {
  background-color: #a9f5ca;
  color: #219a56;
}
.btn_edit:hover,
.btn_edit.active {
  background-color: #219a56;
  color: #fff;
}

.notify svg {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 40px !important;
}
.btn-grp {
  /* color: #00acee; */
  color: var(--secondary-color);
  border: none; /* #main-content {
    padding: 30px;
  } */
}

/* calender button */

.btn-calender {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

.btn-calender button {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  padding: 8px 15px !important;
}

/* end calender button */

.btn-grp button {
  /* color: #00acee; */
  color: var(--secondary-color);
  /* border: 1px solid #00acee; */
  border: 1px solid var(--secondary-color);
  background-color: #c5edfd;
  padding: 5px 15px !important;
}

.notify svg {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 40px !important;
}

.notify .MuiBadge-badge {
  background-color: red !important;
  font-size: 14px !important;
  width: 25px !important; /* #main-content {
    padding: 30px;
  } */
  height: 25px !important;
  border-radius: 50% !important;
}
.notify .MuiBadge-badge {
  background-color: red !important;
  font-size: 14px !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
}

.MuiButtonGroup-root div button.MuiButtonBase-root.MuiButton-root {
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px !important;
  padding: 6px 15px !important;
}

.MuiButtonGroup-root button.MuiButtonBase-root.MuiButton-root {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.MuiButtonGroup-root {
  margin-bottom: 20px;
}

.MuiButton-root {
  font-family: "Montserrat", sans-serif !important;
}

.btn-group .btn-check:checked + .btn-outline-primary {
  color: #fff;
  /* background-color: #00acee; */
  background-color: var(--secondary-color);
  /* border-color: #00acee; */
  border-color: var(--secondary-color);
  font-weight: 500;
}
.btn-group .btn-outline-primary:hover {
  background-color: #c5edfd;
  /* color: #00acee; */
  color: var(--secondary-color);
}
.btn-group .btn-outline-primary {
  /* color: #00acee; */
  color: var(--secondary-color);
  /* border-color: #00acee; */
  border-color: var(--secondary-color);
}

.btn_primary:disabled {
  background-color: #e0e0e0 !important;
  color: #b3b5b3 !important;
}
.long-btn {
  min-width: 200px !important;
}

/* components */

.pagination .page-item.disabled {
  background-color: #e4e4e4;
  color: #333333;
}
.pagination .page-item.disabled .page-link {
  background-color: #effaff;
}
.pagination .page-item .page-link {
  background-color: #c5edfd;
  /* color: #00acee; */
  color: var(--secondary-color);
  border-color: #81dcff;
  font-weight: 500;
}
.pagination .page-item .page-link:hover,
.pagination .page-item .page-link.active {
  /* background-color: #00acee; */
  background-color: var(--secondary-color);
  color: #fff;
}

.pagination .page-item.disabled {
  background-color: #e4e4e4;
  color: #333333;
}
.pagination .page-item.disabled .page-link {
  background-color: #effaff;
}
.pagination .page-item .page-link {
  background-color: #c5edfd;
  /* color: #00acee; */
  color: var(--secondary-color);
  border-color: #81dcff;
  font-weight: 500;
}
.pagination .page-item .page-link:hover,
.pagination .page-item .page-link.active {
  /* background-color: #00acee; */
  background-color: var(--secondary-color);
  color: #fff;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.list-group .list-group-item {
  color: #333333;
  font-weight: 500;
}
.list-group .list-group-item .form-check-input:checked {
  /* background-color: #00acee;
  border-color: #00acee; */
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.card {
  border-color: #c5edfd;
}
.card .card-header {
  background-color: #effaff;
  /* color: #00acee; */
  color: var(--secondary-color);
  font-weight: 500;
}

.form-check.form-switch .form-check-input {
  width: 4em;
  height: 25px;
  margin-left: -2em;
}
.form-check.form-switch .form-check-input:checked {
  /* background-color: #00acee;
  border-color: #00acee; */
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.form-check.form-switch .form-check-label {
  margin-top: 8px;
  margin-left: 10px;
}

.breadcrumb .breadcrumb-item a {
  /* color: #00acee; */
  color: var(--secondary-color);
  text-decoration: none;
}
.breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
}
.breadcrumb .breadcrumb-item.active {
  font-weight: 500;
}

.accordion .accordion-item {
  border: 1px solid #c5edfd;
}
.accordion .accordion-item .accordion-header .accordion-button {
  /* color: #00acee; */
  color: var(--secondary-color);
}
.accordion .accordion-item .accordion-header .accordion-button:focus {
  /* background-color: #00acee; */
  background-color: var(--secondary-color);
  color: #fff;
  font-weight: 500;
}
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  /* background-color: #00acee; */
  background-color: var(--secondary-color);
  color: #fff;
  font-weight: 500;
}

/* Splitter */

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* table */

table {
  /* margin-bottom: 50px; */
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 900;
}

.tr {
  height: 20px !important;
}

.table thead th {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  vertical-align: middle !important;
}
.table tbody td {
  font-size: 15px;
  /* color: #00acee; */
  color: var(--secondary-color);
  border-bottom: 1px solid #cef1ff;
  padding: 15px 20px;
  font-weight: 400;
}

/* drildown table */

.zui-table td a,
.zui-table td a:hover {
  /* color: #00acee; */
  color: var(--secondary-color);
  text-decoration: none;
}

/* forms */

.form-table,
.form-th,
.form-td {
  padding: 5px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: left;
}
.form-table {
  margin: 5px;
  margin-bottom: 30px;
  width: 100%;
  page-break-inside: avoid;
  margin-bottom: 50px !important;
}
.form-input {
  border: none;
  border-bottom: 1px solid black;
  background: none;
}
.form-textarea {
  width: 100%;
  background: none;
}

/* order group table */

.thead-light {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 14px !important;
  padding: 5px 15px !important;
}

.order-group-table .table-cell {
  font-size: 13px;
  padding-top: 10px;
}

.order-group-table .MuiButton-root {
  min-width: 30px;
}

.order-group-table button {
  min-width: 20px;
  margin-right: 2.5px;
  margin-left: 2.5px;
  padding: 5px !important;
}

/* plan board keyframes */

@keyframes blink-early {
  from {
    border-bottom-color: blue;
  }
  to {
    border-bottom-color: white;
  }
}

@keyframes blink-delayed {
  from {
    border-bottom-color: red;
  }
  to {
    border-bottom-color: white;
  }
}

@keyframes blink-tool-issue {
  from {
    /* #main-content {
    padding: 30px;
  } */
    border-bottom-color: green;
  }
  to {
    border-bottom-color: white;
  }
}

@keyframes blink-tool-issue {
  from {
    border-bottom-color: green;
  }
  to {
    border-bottom-color: white;
  }
}

/* pagination */

/* .page-number,
.next-page {
  min-width: 30px !important;
  padding: 3px 8px !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;
  color: #00adef !important;
  background-color: #c5edfd !important;
}

.page-number:hover,
.page-number.active,
.next-page:hover,
.next-page.active {
  background-color: #00acee !important;
  color: #fff !important;
} */

/* .page-number:disabled,
.next-page:disabled{
  background-color: #e0e0e0 !important;
  color: #b3b5b3 !important;
} */

.MuiTablePagination-root {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scrollbars::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* react big calendar css */

.rbc-row-content {
  height: inherit;
  overflow-x: auto;
}

.rbc-event-content {
  font-size: 11px;
}
.rbc-date-cell {
  font-size: 12px;
}

.rbc-today {
  background-color: var(--table-row-color) !important;
}

.rbc-event.rbc-event-allday {
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
  font-weight: bold;
}

.rbc-event.rbc-selected.rbc-event-allday {
  background-color: var(--secondary-color) !important;
  color: #fff !important;
  font-weight: bold;
}

.MuiInputBase-inputMarginDense {
  padding-bottom: 2px !important;
}

.MuiOutlinedInput-root {
  height: 35px;
  font-family: "Montserrat", sans-serif !important;
}

header.MuiPaper-root button[aria-label="Refresh"] {
  background-color: rgba(255, 255, 255, 0.25);
  height: 35px;
  width: 35px;
  margin: 5px;
}

.MuiBadge-colorError {
  font-size: 14px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
}

.RaMenuItemLink-active-40,
.RaMenuItemLink-root-39,
.MuiButton-containedSecondary .jss39 {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-family: "Montserrat", sans-serif !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.RaSidebar-drawerPaper-38 {
  width: 240px;
  box-shadow: 0px 0px 15px #dedede;
  background: #fff !important;
}

/* mui components */

#main-content {
  padding: 30px;
  max-width: 97vw;
}

.panel-title {
  font-size: 18px;
  /* color: #00acee; */
  color: var(--secondary-color);
  margin-top: 15px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableRow-head > th {
  background-color: #fff !important;
  font-size: 15px !important;
  color: #000 !important;

  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}




.MuiTable

.MuiTableRow-head > th span {
  font-size: 13px !important;
}

.MuiButton-containedPrimary {
  font-size: 15px !important;
  min-width: 100px !important;
  font-family: "Montserrat", sans-serif !important;
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
}

.MuiTableCell-sizeSmall {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.MuiTableCell-sizeSmall span {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

/* .MuiTable-root tr:nth-child(odd) { */
/* background-color: #effaff; */
/* background-color: var(--primary-color) !important; */
/* opacity: 0.5 !important; */
/* } */

.MuiTable-root tr:nth-child(even) {
  background-color: #fff;
}

.MuiPaper-root {
  border: 1px solid var(--primary-color) !important;
}
.h-scrollable-table .MuiPaper-root .MuiTable-root {
  border-top: 1px solid var(--primary-color) !important;
}

.MuiTypography-colorInherit {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

/* plan board table view seach field */

.MuiInputBase-inputMarginDense {
  padding-bottom: 2px !important;
}

.MuiOutlinedInput-root {
  height: 35px;
  font-family: "Montserrat", sans-serif !important;
}

header.MuiPaper-root button[aria-label="Refresh"] {
  background-color: rgba(255, 255, 255, 0.25);
  height: 35px;
  width: 35px;
  margin: 5px;
}

.MuiBadge-colorError {
  font-size: 14px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
}

.RaMenuItemLink-active-40,
.RaMenuItemLink-root-39,
.jss39 {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-family: "Montserrat", sans-serif !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.RaSidebar-drawerPaper-38 {
  width: 240px;
  box-shadow: 0px 0px 15px #dedede;
  background: #fff !important;
}

.MuiMenuItem-dense {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
  padding-top: 8px !important;

  padding-bottom: 8px !important;
}

.MuiOutlinedInput-root {
  height: 45px !important;
}

.MuiFormLabel-root {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #ceeff7 !important;
}

.MuiInputBase-input {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

label + .MuiInput-formControl {
  margin-top: 18px !important;
}

.panel-title {
  font-size: 18px;
  /* color: #00acee; */
  color: var(--secondary-color);
  margin-top: 15px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableRow-head > th {
  background-color: #fff !important;
  font-size: 15px !important;
  color: #000 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.MuiTableRow-head > th span {
  font-size: 13px !important;
}

.MuiTableCell-sizeSmall {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
  border-bottom: 1px solid var(--primary-color) !important; /*table row borders */
}

.MuiTableCell-sizeSmall span {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTable-root tr:nth-child(odd) {
  /* background-color: #effaff; */
  /* background-color: var(--table-row-color) !important; */
  /* opacity: 0.5 !important; */
}

.MuiTable-root tr:nth-child(even) {
  background-color: #fff;
}

.MuiTable-root tr:hover {
  background-color: #f3f3f3;
  transition: 0.2s;
}

/* .MuiTable-root tr {
  border-top: 1px solid var(--primary-color) !important;
} */

.MuiPaper-root {
  border: 1px solid var(--primary-color) !important;
}

.h-scrollable-table .list-page .MuiPaper-root {
  border: 1px solid var(--primary-color) !important;
  border-top: transparent !important;
}

.MuiTablePagination-root {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTypography-colorInherit {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiButton-textPrimary {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.MuiMenuItem-dense {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.MuiOutlinedInput-root {
  height: 45px !important;
}

.makeStyles-formControl {
  background-color: #ebf6fd;
}

.MuiFormLabel-root {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #ceeff7 !important;
}

.MuiInputBase-input {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

label + .MuiInput-formControl {
  margin-top: 18px !important;
}
.MuiDialog-paper.MuiDialog-paperScrollPaper .MuiDialogContent-root {
  overflow-x: hidden;
}
.rjsf h5 {
  /* color: #00acee; */
  color: var(--secondary-color);
  font-weight: 600;
}

.rjsf h6 {
  margin-bottom: 15px;
}

.rjsf .MuiPaper-root {
  border: none !important;
  background-color: transparent !important;
  padding: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .rjsf .MuiPaper-root .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true {
  border: 1px solid #eee !important;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #eee;
} */

.rjsf .MuiBox-root {
  padding: 0px !important;
}

.rjsf .MuiTypography-caption {
  color: #000;
  font-weight: 600;
}
.MuiBox-root .react-checkbox-tree ol li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  /* color: #616161; */
}

.MuiTypography-root.MuiTypography-caption.MuiTypography-colorTextSecondary {
  font-size: 13px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin-top: 5px;
}

.MuiTypography-root.MuiTypography-h5,
.MuiTypography-root.MuiTypography-subtitle2 {
  font-family: "Montserrat", sans-serif !important;
}

.MuiButtonGroup-root button.MuiButtonBase-root.MuiButton-root {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.MuiButtonGroup-root {
  margin-bottom: 20px;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
  width: 100%;
}

.MuiButton-root {
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableRow-root.tr.MuiTableRow-head {
  height: 70px !important;
  padding-left: 0px !important;
}

.MuiTableRow-root.tr td {
  border-bottom: none !important;
}

.MuiTableRow-root.tr {
  position: relative !important;
}

.MuiInputBase-inputMarginDense {
  padding-bottom: 2px !important;
}

.MuiOutlinedInput-root {
  height: 35px;
  font-family: "Montserrat", sans-serif !important;
}

header.MuiPaper-root button[aria-label="Refresh"] {
  background-color: rgba(255, 255, 255, 0.25);
  height: 35px;
  width: 35px;
  margin: 5px;
}

.MuiBadge-colorError {
  font-size: 14px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
}

/* new build */

.RaFormInput-input-54 {
  width: 100% !important;
}

/* .RaToolbar-desktopToolbar-61,
.RaToolbar-desktopToolbar-62{
  padding: 0px 5px !important;
} */

.MuiTypography-h6 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  margin-top: 10px !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

.RaSelectArrayInput-chip-62 {
  margin: 10px 5px 0px 0px !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.RaFormInput-input-111,
.RaFormInput-input-115,
.RaFormInput-input-563,
.RaFormInput-input-617 {
  width: 100% !important;
}

.MuiTextField-root {
  width: 100% !important;
}

.MuiSelect-selectMenu {
  width: 100% !important;
}

.previous-page,
.page-number,
.next-page {
  min-width: 30px !important;
  padding: 1px 8px !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
  margin-right: 2px !important;
  color: var(--secondary-color) !important;
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  align-items: center !important;
}

.next-page {
  margin-right: 10px !important;
  padding-right: 2px !important;
}

.previous-page {
  padding-left: 2px !important;
}

.previous-page:hover,
.page-number:hover,
.next-page:hover {
  /* background-color: #00acee !important; */
  background-color: var(--secondary-color) !important;
  color: #fff !important;
}

.RaPaginationActions-currentPageButton {
  /* background-color: #00acee !important; */
  background-color: var(--secondary-color) !important;
  color: white !important;
}

/* .page {
  background-color: red !important;
} */

/* .page-number,
.next-page { */
/* width: 30px !important; */
/* min-width: 30px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
  margin-bottom: 0px !important;
} */

.MuiTableRow-root .MuiCardContent-root {
  padding: 0px 15px !important;
}

.MuiTableRow-root .MuiCardContent-root .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFormControl-marginDense {
  width: 100% !important;
}

/* .MuiDialogContent-root{
  background-color: #effaff !important;
  border: 1px solid #cef1ff !important;
} */

.MuiTypography-body2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

.sc-iqHYGH.lorCQr.btn-danger li,
.sc-iqHYGH.lorCQr.btn-danger li svg {
  color: #f44336 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

/* changes start from here */

.RaMenuItemLink-active-40 {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

.MuiInputBase-root {
  min-width: 100px;
}

.MuiTable-root.table.table-sm thead th {
  white-space: nowrap !important;
  padding: 5px 10px !important;
  overflow-x: hidden;
}

.MuiTable-root.table.table-sm thead th .MuiFormControl-marginDense {
  width: 60% !important;
  min-width: 80px !important;
}

#react-admin-title {
  color: #fff !important;
  margin-top: 0px !important;
}

.MuiTypography-root.MuiTypography-caption.MuiTypography-colorTextSecondary {
  font-size: 13px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin-top: 5px;
}

.MuiTypography-root.MuiTypography-h5,
.MuiTypography-root.MuiTypography-subtitle2 {
  font-family: "Montserrat", sans-serif !important;
}

.MuiButtonGroup-root button.MuiButtonBase-root.MuiButton-root {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.MuiButtonGroup-root {
  margin-bottom: 20px;
}

button.btn-danger .MuiButton-label {
  font-size: 14px;
}

.MuiButtonGroup-root div button.MuiButtonBase-root.MuiButton-root {
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px !important;
  padding: 6px 15px !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
  width: 100%;
}

.MTableToolbar-title-161 {
  min-width: 100px;
  overflow: show;
}

.long-btn {
  min-width: 200px !important;
}

.thead-light {
  background-color: #fff !important;
  color: #000 !important;
  font-size: 14px !important;
  padding: 5px 15px !important;
}

.order-group-table .MuiButton-root {
  min-width: 30px;
}

.order-group-table button {
  min-width: 20px;
  margin-right: 2.5px;
  margin-left: 2.5px;
  padding: 5px !important;
}

.recharts-text.recharts-cartesian-axis-tick-value {
  margin-top: 10px;
}

.react-daterange-picker__wrapper {
  display: flex;
  border: thin solid white !important;
  padding-left: 10px;
  overflow: hidden;
}

.react-daterange-picker__wrapper button{
  padding: unset !important;
}

.react-daterange-picker__clear-button.react-daterange-picker__button{
  color: red;
}

.MTableToolbar-title-162 {
  overflow: inherit !important;
}

.MuiBox-root.MuiBox-root-74 .MuiButton-containedPrimary {
  font-size: 13px !important;
  margin-right: 5px !important;
  min-width: 80px !important;
  margin-bottom: 5px !important;
}

.Pane.horizontal.Pane2 table tbody div {
  /* height: 500px !important; */
}

tr.MuiTableRow-root.tr td .MuiTypography-root.MuiTypography-body1 {
  height: 30px;
  width: 100px;
  font-size: 12px;
  margin-left: -50px;
  margin-top: 20px;
  white-space: pre-line;
  word-break: break-all;
  font-size: 11px;
}

tr.MuiTableRow-root.tr
  td.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
  margin-top: 20px;
}

tr.MuiTableRow-root.tr
  td
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
  margin-top: 20px;
}

.MuiTableRow-root.MuiTableRow-footer .MuiTableCell-sizeSmall {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.zui-table td a,
.zui-table td a:hover {
  /* color: #00acee; */
  color: var(--secondary-color);
  text-decoration: none;
}

.RaListToolbar-toolbar-406 {
  padding-bottom: 10px;
}

.Component-appBar-14.MuiAppBar-colorPrimary {
  padding-top: 0px !important;
}

.MuiTypography-root.MuiTypography-h6 {
  overflow: revert !important;
  padding-right: 30px;
  white-space: unset !important;
}

/* .page-number,
.next-page {
  color: #00adef !important;
} */

.MuiDialog-paper.MuiDialog-paperScrollPaper .MuiDialogContent-root {
  overflow-x: hidden;
}

.MuiBox-root.MuiBox-root-263 {
  font-size: 12px !important;
}

.MuiBox-root.MuiBox-root-263 button {
  font-size: 12px !important;
  margin: 5px !important;
}

.MuiPaper-root.MuiCard-root.card.MuiPaper-outlined.MuiPaper-rounded {
  height: 255px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSizeSmall.MuiButton-sizeSmall {
  background-color: #c5edfd;
  /* color: #00acee; */
  color: var(--secondary-color);
  border: none;
  padding: 6px 15px !important;
  font-size: 15px;
  margin-bottom: 15px;
}

.MuiBox-root.MuiBox-root-534 button,
.MuiBox-root.jss74 .MuiCardActions-root.MuiCardActions-spacing button,
.MuiBox-root.MuiBox-root-128
  .MuiCardActions-root.MuiCardActions-spacing
  button {
  font-size: 13px !important;
  margin: 5px !important;
  min-width: 50px !important;
}

.btn-danger li {
  color: #f44336 !important;
  padding: 8px 15px;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}

.btn-danger li .MuiListItemIcon-root {
  color: #f44336 !important;
  padding-right: 0px !important;
  min-width: 25px !important;
}

.text_inline {
  display: inline-block;
}

table {
  /* margin-bottom: 50px; */
  width: 100%;
}

.sub_con_lab label span {
  border: none;
  text-align: left;
}

/* edit create button alignment */

.edit_crate {
  display: flex;
  justify-content: flex-end;
  /* align-content: flex-end; */
  margin-right: 10;
}

.sub_con_lab span {
  border-bottom: 1px solid #eee;
  width: 100%;
  display: block;
  max-width: 100%;
  position: absolute;
  top: -10px;
  right: 15px;
  text-align: right;
  padding-bottom: 10px;
  min-height: 30px;
}

.rjsf h5 {
  /* color: #00acee; */
  color: var(--secondary-color);
  font-weight: 600;
}

.rjsf h6 {
  margin-bottom: 15px;
}

.rjsf .MuiPaper-root {
  border: none !important;
  background-color: transparent !important;
  padding: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .rjsf .MuiPaper-root .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true {
  border: 1px solid #eee !important;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #eee;
} */

.rjsf .MuiBox-root {
  padding: 0px !important;
}

.rjsf .MuiTypography-caption {
  color: #000;
  font-weight: 600;
}

.table-width-custom {
  width: 94vw;
  /* width: 85vw; */
  overflow-x: auto;
  height: 100vh;
}

@media screen and (max-width: 1540px) {
  .table-width-custom {
    width: 80vw;
  }
}

@media screen and (max-width: 1280px) {
  .table-width-custom {
    width: 76vw;
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
  z-index: 9999;
}

/* settings  page */

.settings_block .MuiSvgIcon-root {
  /* color: #00acee; */
  color: var(--secondary-color);
  width: 100%;
  font-size: 100px;
}

.btn_primary:disabled {
  background-color: #e0e0e0 !important;
  color: #b3b5b3 !important;
}

/* forms */

.form-table,
.form-th,
.form-td {
  padding: 5px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: left;
}
.form-table {
  margin: 5px;
  margin-bottom: 30px;
  width: 100%;
  page-break-inside: avoid;
  margin-bottom: 50px !important;
}
.form-input {
  border: none;
  border-bottom: 1px solid black;
  background: none;
}
.form-textarea {
  width: 100%;
  background: none;
}

.MuiMenuItem-dense {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.MuiOutlinedInput-root {
  height: 45px !important;
}

.makeStyles-formControl {
  background-color: #ebf6fd;
}

.MuiFormLabel-root {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
}

.MuiOutlinedInput-notchedOutline {
  /* border-color: #ceeff7 !important; */
  border-color: var(--secondary-color) !important ;
}

.MuiInputBase-input {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

label + .MuiInput-formControl {
  margin-top: 18px !important;
}
.MuiDialog-paper.MuiDialog-paperScrollPaper .MuiDialogContent-root {
  overflow-x: hidden;
}
.rjsf h5 {
  /* color: #00acee; */
  color: var(--secondary-color);
  font-weight: 600;
}

.rjsf h6 {
  margin-bottom: 15px;
}

/* .rjsf .MuiPaper-root {
  border: none !important;
  background-color: transparent !important;
  padding: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
} */

/* .rjsf .MuiPaper-root .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true {
  border: 1px solid #eee !important;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #eee;
} */

/* .rjsf .MuiBox-root {
  padding: 0px !important;
} */

.rjsf .MuiTypography-caption {
  color: #000;
  font-weight: 600;
}

.MuiTypography-root.MuiTypography-caption.MuiTypography-colorTextSecondary {
  font-size: 13px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin-top: 5px;
}

.MuiTypography-root.MuiTypography-h5,
.MuiTypography-root.MuiTypography-subtitle2 {
  font-family: "Montserrat", sans-serif !important;
}

.MuiButtonGroup-root button.MuiButtonBase-root.MuiButton-root {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.MuiButtonGroup-root {
  margin-bottom: 20px;
}

.MuiButtonGroup-root div button.MuiButtonBase-root.MuiButton-root {
  /* background-color: #c5edfd !important; */
  background-color: var(--primary-color) !important;
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px !important;
  padding: 6px 15px !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
  width: 100%;
}

.MuiButton-root {
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableRow-root.tr.MuiTableRow-head {
  height: 70px !important;
  padding-left: 0px !important;
}

.MuiTableRow-root.tr td {
  border-bottom: none !important;
}

.MuiTableRow-root.tr {
  position: relative !important;
}

.MuiInputBase-inputMarginDense {
  padding-bottom: 2px !important;
}

.MuiOutlinedInput-root {
  height: 35px;
  font-family: "Montserrat", sans-serif !important;
}

header.MuiPaper-root button[aria-label="Refresh"] {
  background-color: rgba(255, 255, 255, 0.25);
  height: 35px;
  width: 35px;
  margin: 5px;
}

.MuiBadge-colorError {
  font-size: 14px !important;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
}

.MuiFormControlLabel-root {
  margin-bottom: 0 !important;
}

.MuiChip-label {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

.rbc-toolbar button {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  /* border: 1px solid #00acee !important; */
  border: 1px solid var(--secondary-color) !important;
  padding: 8px 15px !important;
  font-size: 15px !important;
}

.MuiChip-sizeSmall {
  height: 30px !important;
  line-height: 36px;
  border: 1px solid #333 !important;
  background-color: #e2e2e2 !important;
  padding: 0px 5px;
}

.MuiPaper-elevation1.MuiPaper-rounded {
  width: 100% !important;
  background-color: #fff;
}

.MuiPaper-elevation1.MuiPaper-rounded h3{
  font-weight: bold;
  /* margin-bottom: 25px; */
  /* text-transform: uppercase; */
  /* background: #3f51b5; */
  padding: 15px 0px;
  /* color: #fff; */
  color: var(--secondary-color) !important;
  border-radius: 5px;
  font-size: 24px;
  text-align: left;
  padding: 5px 5px;
}

span.MuiTypography-body2 {
  /* color: #00acee !important; */
  color: var(--secondary-color) !important;
  white-space: break-spaces;
  /* for to get full width in tables (to solve order codes numbers print in several lines) */
  /* word-break: break-all; */
}

.MuiTableRow-root > td {
  height: 30px !important;
  font-size: 14px !important;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableRow-root > td.MuiTableCell-footer {
  height: auto !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn-success.px-3.py-2 {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary,
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTreeItem-content {
  flex-wrap: wrap;
}
0.20s
/* permission */

.permission-edit-btn {
  margin-right: 100px !important;
}

/* chip margin */

.MuiTable-root.RaDatagrid-table-69 td:nth-child(5) {
  width: 100px;
}

.RaSelectArrayInput-chip-124 {
  margin: 9px !important;
}

/* calendar */

.MuiBox-root .MuiBox-root .MuiGrid-root.MuiGrid-container {
  /* border: 1px solid gray ; */
  border: 1px solid #eee !important;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #eee;
}

.MuiBox-root .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center {
  border: none !important;
  box-shadow: none !important;
}

/* side bar font color */
.makeStyles-listItemText-43 {
  color: var(--secondary-color) !important ;
}

div.css-1fvmzjn {
  color: var(--secondary-color) !important ;
}

.row1 {
  background-color: rgb(231, 227, 227) !important;
}

/* style changes for table odd rows */

.MuiTable-root tr:nth-child(odd) {
  background-color: var(--table-row-color);
}

/* logout model hover effect remove */
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded
  .MuiListItem-button:hover {
  background-color: unset !important;
}
/* TO STOP BLUE outline IN INPUT FIELDS */
.RaAutocompleteSuggestionList-suggestionsContainer {
  z-index: unset !important;
}
/* [class*="RaAutocompleteSuggestionList-suggestionsContainer-"] {
  z-index: unset !important;
} */

/* close icon hover change to mouse pointer */
.MuiSvgIcon-root {
  cursor: pointer;
}
/* plant-> edit chips  */
/* [class*="RaSelectArrayInput-chip-"] {
  margin: 10px !important;
} */

.MuiButtonGroup-groupedHorizontal:last-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.MuiButtonGroup-groupedHorizontal:not(:last-child) {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.range_filter_chip {
  height: 22px !important;
}

/* PlanBoard date navigating arrows */

.MuiButton-endIcon,
.MuiButton-startIcon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.lineCard {
  width: 131px !important;
}
/* delay quantity page chips style  */

.delayQtyChip {
  margin-right: 25.7% !important;
  margin-left: 27.5% !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  display: inline-block !important;
  overflow: revert !important;
}
/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
  .delayQtyChip {
    margin-right: 20.7% !important;
    margin-left: 20.5% !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    display: inline-block !important;
    overflow: revert !important;
  }
}

/* delay quantity page chips style  */

.delayQtyChipEndDate {
  display: inline-block !important;
  overflow: revert !important;
  width: 95px;
  text-align: center !important;
  position: relative !important;
}
/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
  .delayQtyChipEndDate {
    display: inline-block !important;
    overflow: revert !important;
    width: 95px;
    text-align: center !important;
    position: relative !important;
  }
}
/* logout button group */

.logout_btn_group {
  margin: 0px 15px;
  background-color: var(--primary-color) !important;
  box-shadow: unset !important;
}

.logout_btn_text {
  color: white !important;
  font-weight: 600 !important;
}
/* progress ui changes */

/* plan board - settings - Add New Group Button */
.button-card {
  border: 1px solid var(--primary-color);
  width: fit-content;
  border-radius: 4px;
}

/* hide unwanted react-admin snackbars */

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter {
  display: none;
}
/* 
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter .MuiSnackbarContent-root .RaNotification-warning-{
  display: none;

} */
.h-scrollable-table
  .list-page
  .MuiToolbar-root.MuiToolbar-dense.MuiToolbar-gutters {
  position: fixed;
  top: 70px;
  width: inherit;
  /* border-bottom: 1px solid var(--primary-color) !important; */
}

.h-scrollable-table
  .MuiTablePagination-root
  .MuiToolbar-root.MuiToolbar-dense.MuiTablePagination-toolbar.MuiToolbar-gutters {
  top: unset;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
}

/* planboard ->settings -> supported groups */

ul.MuiList-root.MuiMenu-list.MuiList-dense {
  max-height: 200px;
}

#demo-mutiple-chip.MuiList-root.MuiMenu-list.MuiList-dense {
  max-height: 200px;
}
/* .MuiToolbar-root.MuiToolbar-dense.MuiToolbar-gutters .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl.MuiInputBase-marginDense.MuiFilledInput-marginDense{
  min-width: 300px
} */
#supported_operation_groups {
  min-width: 300px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  color: var(--secondary-color) !important;
}


.MuiPaper-root.makeStyles-paper-12.MuiPaper-elevation1.MuiPaper-rounded{
  background-color: unset;
}

.gridblock div:first-child{
  background-color: #f0f1f7 !important;
  border: none !important;
  box-shadow: 0px 0px 6px #cbc8f1;
  height: 300px;
}

.gridblock div:first-child a{
  height: unset !important;
}

.gridblock div:first-child .MuiButton-root{
  color: var(--secondary-color);
  background-color: white;
  border: 1px solid #fff;
}

.gridblock div:first-child .MuiButton-root:hover{
  background-color: var(--primary-color);
}


.analytics_wrapper{
  border: none !important;
  background-color: white;
}

.analytics_wrapper .MuiPaper-root{
  border: none;
  /* background-color: turquoise; */
}

.analytics_div{
  border: none !important;
  box-shadow: none !important;
  margin-top: 15px;
}

.flows ,
.tasks-wrapper ,
.individual_task{
  border: none !important;
  box-shadow: 0px 0px 5px #d1d1d1 !important;
  background-color: #ffffff !important;
  margin-top: 15px;
  border-radius: 10px !important;
}

.analytics_div .MuiInputBase-root ,
.analytics_div .MuiPaper-root {
  color: var(--primary-color) !important;
} 

.analytics_div .MuiTab-textColorPrimary{
  color: unset !important;
}

.analytics_div .MuiTableCell-root{
  padding: 6px !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 600;
  /* background: #f0f1f7;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 5px #ccc; */
  color: var(--secondary-color) !important;
}

.sub_tab{
  min-width: 100px !important;
}

.sub_tab.MuiTab-textColorPrimary.Mui-selected{
  /* background-color: #3f51b5;
  color: #fff !important; */
  font-size: 14px !important;
}


.MuiMenuItem-root{
  color: var(--secondary-color) !important;
  font-family: "Montserrat", sans-serif !important;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: rgb(175 172 224) !important;
}


.analytics_div .react-daterange-picker {
  display: unset !important;
}

.MuiDialog-paperWidthLg {
  max-width: 95vw !important;
}

.type-dropdown .MuiSelect-root{
  min-width: 280px;
}


.analytics_div .MuiTextField-root {
  min-width: 280px !important;
}

.analytics_div .MuiOutlinedInput-root {
  /* height: 48px !important; */
  height: 40px !important;
  margin-top: 4px;
}

.analytics_wrapper .dashboard_delayed_row , .modal_task_table .dashboard_delayed_row , .analytics_wrapper .dashboard_delayed_row .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall , .modal_task_table .dashboard_delayed_row  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall , .analytics_wrapper .dashboard_delayed_row .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiLink-button.MuiTypography-body1.MuiTypography-colorPrimary {
  background-color: #ffb4b0 !important;
  color: #e71c0d !important;
  transition-delay: 0.05s;
}



.dashboard_delayed_row_legend , .dashboard_delayed_row_legend .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall , .dashboard_delayed_row_legend .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiLink-button.MuiTypography-body1.MuiTypography-colorPrimary
{
  background-color: #ffb4b0 !important;
  color: #ffb4b0 !important;
}

.analytics_wrapper .dashboard_delayed_row:hover , .modal_task_table .dashboard_delayed_row:hover , .analytics_wrapper .dashboard_delayed_row:hover .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall, .modal_task_table .dashboard_delayed_row:hover .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall , .analytics_wrapper .dashboard_delayed_row:hover .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiLink-button.MuiTypography-body1.MuiTypography-colorPrimary {
  background-color: #d15249 !important;
  color: #fff !important;
}

/* .analytics_wrapper .dashboard_on_time_row , .modal_task_table .dashboard_on_time_row{
  background-color: #93e7b8 !important;
  color: #126416 !important;
} */

.dashboard_on_time_row_legend{
  background-color: #93e7b8 !important;
  color: #93e7b8 !important;
}

/* .analytics_wrapper .dashboard_on_time_row:hover , .modal_task_table .dashboard_on_time_row{
  background-color: #48b34deb !important;
  color: #fff !important;
} */

.analytics_wrapper .MuiTableCell-body{
  color: unset;
}

.analytics_wrapper .MuiPaper-root {
  border: 1px solid #ffffff !important;
}

.analytics_wrapper .MuiTableCell-sizeSmall {
  border-bottom: 1px solid #ffffff !important;
}
