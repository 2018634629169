.lines {
  /* display: inline-grid; */
  /* grid-gap: 50px; */
  height: 25px;
  /* justify-content: space-evenly; */
  /* grid-template-columns: repeat(100px);  */
  /* background-color: rgb(182, 182, 182); */
  position: relative;
}

.buckets {
  /* display: inline-grid; */
  /* grid-gap: 50px; */
  /* justify-content: space-evenly; */
  /* grid-template-columns: repeat(100px); */
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  /* border-radius: 5px; */
  border-color: black;
  /* border-width: 2px; */
  /* padding: 5px; */
  height: 20px;
  font-size: 8px;
  /* width: 100px; */
  /* font-size: 10px; */
  /* gap: 10px; */
  /* top:0px; */
  position: absolute;
}

.bucket-start {
  position: absolute;
  display: inline-grid;
  grid-gap: 50px;
  justify-content: space-evenly;
  grid-template-columns: repeat(100px);
  background-color: rgb(156, 240, 255);
  color: rgb(0, 0, 0);
  /* border-radius: 5px; */
  border-color: black;
  border-width: 2px;
  padding: 5px;
  /* height: 30px; */
  width: 50px;
  font-size: 10px;
  z-index: 10;
  gap: 10px;
}

.wrapper {
  display: grid;
grid-gap: 10px;
  grid-template-columns: [col] 100px [col] 100px [col] 100px [col] 100px  ;
  grid-template-rows: [row] auto [row] auto [row] ;
  background-color: #fff;
  color: #444;
  gap: 10px;
}

.flex-container {
        display: flex;
        align-items: center; /* Use another value to see the result */
        width: 100%;
        /* height: 200px; */
        background-color: #1faadb;
      }