@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

.zui-table {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 13px 'Montserrat', sans-serif;
  width: 3000px;
  padding: 10px;
  margin: 10px;
}
.zui-table thead th {
  /* background-color:#00acee; */
  background-color: var(--secondary-color) ;
  border: solid 1px #ddeeee;
  color: #e7eaf3;
  text-align: left;
  height: 20px;
  padding: 10px;
}

.zui-table thead th p{
  margin-bottom: 0px;
}

.zui-table tbody td {
  border: solid 1px #ddeeee;
  color: #333;
  text-shadow: 1px 1px 1px #fff;
  padding: 10px;
}

.bar {
  padding: 3px;
}

.root {
  margin-top: 10px;
 
}
.title {
  font-size: 14;
}
.action {
  display: "flex";
  justify-content: "space-between";
}
.bc {
  background-color: rgb(113, 221, 221);
}
.search {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 0.5px solid #999;
  background: transparent;
  height: 40px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #3d3d3c;
  font-weight: 400;
  padding-left: 15px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.search:hover {
  background-color: rgba(102,102,102,0.2);
  border-bottom: 0.5 solid #999;
  border-top: 0.1 solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.pagination {
  align-content: flex-end;
  margin-top: 15px;
  height: 33px;
  margin-left: 25px;
}

*:focus {
  outline: none;
}
