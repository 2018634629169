.NotFoundContent {
  margin-top: 35vh !important;
  }

  .NotFoundContent h3{
    font-size: 2.0em;
  }

  .NotFoundContent p{
    font-size: 1.2em;
  }