@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

.font{
  font-size: 18px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  margin-top: 5px;
}

#table-export {
  font-family: Arial, Helvetica, sans-serif;
 
  width: 3000px;
  border-radius: 5px;
}
 
#table-export td, #table-export th {
  border: 1px solid #ddd;
  padding: 8px;
}
  
#table-export tr:nth-child(even){background-color: #f2f2f2;}
 
#table-export tr:hover {background-color: #ddd;}
  
#table-export th, tfoot td{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--primary-color) !important;
  color: white;
}

.button{
  height: 30px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: var(--primary-color) !important;
  margin-left: 5px;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 14px;
  margin: 10;
} 



.export-button{
  position: absolute;
  right: 20px;
  margin-top: 10px;
  /* height: 30px; */
  /* padding: 15px 32px; */
  /* text-align: center; */
  /* text-decoration: none;
  display: inline-block;
  background-color: #1db0e9;
  margin-left: 5px;
  color: white;
  border: none;
  font-weight: 600;
  font-size: 14px; */
}

.table-element{
  height: 30px;
  margin-left: 5px;
  border: none;
}

.bottom-element{
  height: 30px;
  margin-left: 5px;
}

#bottom-panel{
  left: 35%;
  position: absolute;
  bottom: 40px;
  margin: auto;
}