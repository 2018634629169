.react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
    flex-grow: 1;
    display: flex;
    padding: 0 2px;
    align-items: baseline;
    box-sizing: content-box;
    color: var(--secondary-color) !important;
}
.react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    color: var(--secondary-color) !important;
}
.react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    /* padding: 0 1px; */
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    color: var(--secondary-color) !important;
}  